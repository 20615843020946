import React from 'react';
import { graphql } from 'gatsby';

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard';
import Quotation from '../../components/courses/enterprise/Quotation';
import useCoursesPath from '../../hooks/useCoursesPath';

export default function QuotationTemplate({ data: { course } }) {
  const { getCourseQuotationPath } = useCoursesPath();

  return (
    <AuthGuard
      emailMustVerified={true}
      redirectTo={getCourseQuotationPath(course.slug)}
      preserveSearch={true}
    >
      <Quotation {...course}></Quotation>
    </AuthGuard>
  );
}

export const pageQuery = graphql`
  query EnterpriseQuotationQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
    }
  }
`;
