import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { navigate } from 'gatsby';

import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase';
import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle';
import Modal from '@babelcoder/gatsby-theme-base/src/components/Modal';
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg';
import SuccessIcon from '@babelcoder/gatsby-theme-base/assets/images/success.svg';
import Input from '../../../../../site/src/components/auth/Input';
import NotRegisterOnlyGuard from '../NotRegisterOnlyGuard';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';

const Wrapper = styled.article`
  ${({ theme }) => css`
    max-width: 768px;
    margin: ${theme.spacers.normal} auto;
    padding: 0 ${theme.spacers.normal};
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1200};
    font-size: ${theme.fontSizes.large};
    text-align: center;
    text-decoration: underline;
  `}
`;

const Desc = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
    margin: 1rem 0;
  `}
`;

const RequestQuotationButton = styled.button.attrs({ type: 'submit' })`
  ${({ theme, hasError }) => css`
    display: block;
    margin: 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray700};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;

    ${!hasError &&
    css`
      background-color: ${theme.colors.main.primary};
      cursor: pointer;
    `}
  `}
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;

const buttonStyle = css`
  ${({ theme }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;
  `}
`;

const EmailLink = styled.a.attrs({ href: 'mailto:babelcoder@gmail.com' })`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CloseModal = styled.button.attrs({ type: 'button' })`
  ${buttonStyle};

  cursor: pointer;
`;

const HighlightText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-family: ${theme.fontFamily.heading};
  `}
`;

const Quotation = ({ slug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [next3DaysDate, setNext3DaysDate] = useState(null);
  const { getCoursePath } = useCoursesPath();
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const {
    handleSubmit,
    register,
    errors,
    formState: { isValid, touched },
  } = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      organizationName: yup.string().required(),
      address: yup.string().required(),
      organizationId: yup.number().required(),
      contactEmail: yup.string().required(),
      contactTel: yup.string(),
      numOfTrainees: yup.string().required(),
      traineeBackground: yup.string().required(),
      comment: yup.string().required(),
    }),
  });

  const requestQuotation = async ({
    organizationName,
    address,
    organizationId,
    contactEmail,
    contactTel,
    numOfTrainees,
    traineeBackground,
    comment,
  }) => {
    try {
      const firestore = await getFirebaseFirestore();
      await firestore.collection(`users/${user.uid}/quotations`).doc(slug).set({
        uid: user.uid,
        email: user.email,
        createdAt: new Date(),
        updatedAt: new Date(),
        organizationName,
        address,
        organizationId,
        contactEmail,
        contactTel,
        numOfTrainees,
        traineeBackground,
        comment,
        success: false,
      });
      await firestore.doc(`users/${user.uid}`).set(
        {
          quotations: {
            [slug]: true,
          },
        },
        { merge: true }
      );
    } catch (err) {}
  };

  const showModal = useCallback(() => {
    const now = new Date();
    const next = now.setHours(now.getHours() + 24);
    const nextDate = new Intl.DateTimeFormat('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(next);
    const nextTime = new Intl.DateTimeFormat('th-TH', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(next);

    setNext3DaysDate(`${nextDate} เวลา ${nextTime} น.`);
    setIsModalOpened(true);
  }, [setNext3DaysDate, setIsModalOpened]);

  const closeModal = useCallback(() => {
    navigate(getCoursePath(slug), { replace: true });
  }, [slug, getCoursePath]);

  const submit = async (value) => {
    try {
      setIsLoading(true);
      await requestQuotation(value);
      setIsLoading(false);
      showModal();
    } catch (error) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'danger',
          title: 'การดำเนินการไม่สำเร็จ',
          message: 'เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาติดต่อผู้ดูแลระบบ',
        },
      });
    }
  };

  return (
    <NotRegisterOnlyGuard slug={slug}>
      <Wrapper>
        <Title>ขอใบเสนอราคา</Title>
        <Desc>
          โปรดกรอกข้อมูลต่อไปนี้เพื่อใช้ในการออกใบเสนอราคา
          โดยเราจะทำการจัดส่งใบเสนอราคาให้กับท่านผ่านทางอีเมล์ที่ท่านให้ไว้ภายในระยะเวลา
          3 วัน
        </Desc>
        <form onSubmit={handleSubmit(submit)}>
          <Input
            type="text"
            name="organizationName"
            title="ชื่อบริษัท / องค์กร / หน่วยงาน"
            ref={register}
            placeholder="เช่น บริษัท โค้ดศาสตร์ จำกัด"
            error={errors.organizationName}
          ></Input>
          <Input
            type="text"
            name="address"
            as="textarea"
            rows={5}
            title="ที่อยู่บริษัท / องค์กร / หน่วยงาน"
            ref={register}
            placeholder="ใช้สำหรับระบุในใบเสนอราคา"
            error={errors.address}
          ></Input>
          <Input
            type="text"
            name="organizationId"
            title="เลขประจำตัวผู้เสียภาษีอากรบริษัท / องค์กร / หน่วยงาน"
            ref={register}
            placeholder="ใช้สำหรับระบุในใบเสนอราคา"
            error={errors.organizationId}
          ></Input>
          <Input
            type="text"
            name="contactEmail"
            title="อีเมล์สำหรับจัดส่งใบเสนอราคา"
            ref={register}
            placeholder="เช่น babelcoder@gmail.com"
            error={errors.contactEmail}
          ></Input>
          <Input
            type="text"
            name="contactTel"
            title="เบอร์โทรศัพท์"
            ref={register}
            placeholder="ใช้สำหรับการติดต่อกลับเพื่อขอข้อมูลเพิ่มเติม"
            error={errors.contactTel}
          ></Input>
          <Input
            type="text"
            name="numOfTrainees"
            title="จำนวนผู้เข้าอบรม"
            ref={register}
            placeholder="เช่น 15 - 20 คน"
            error={errors.numOfTrainees}
          ></Input>
          <Input
            type="text"
            name="traineeBackground"
            title="ภูมิหลังผู้เข้าอบรม"
            ref={register}
            placeholder="เช่น ผู้เข้าอบรมเป็นนักพัฒนา .Net มีประสบการณ์การพัฒนาเว็บมาก่อน"
            error={errors.traineeBackground}
          ></Input>
          <Input
            type="text"
            name="comment"
            title="ข้อมูลเพิ่มเติม"
            as="textarea"
            rows={5}
            ref={register}
            placeholder="เช่น ต้องการเพิ่มเนื้อหาบางอย่าง"
            error={errors.comment}
          ></Input>
          {isLoading ? (
            <LoadingImage></LoadingImage>
          ) : (
            <RequestQuotationButton
              hasError={Object.keys(touched).length === 0 || !isValid}
            >
              ลงทะเบียน
            </RequestQuotationButton>
          )}
        </form>
        {isModalOpened && (
          <Modal onClose={closeModal}>
            <ModalWrapper>
              <StyledSuccessIcon></StyledSuccessIcon>
              <PageTitle>การดำเนินการสำเร็จ</PageTitle>
              <div>
                เราจะดำเนินการตรวจสอบหลักฐานการชำระเงินของท่านภายในวันที่{' '}
                <HighlightText>{next3DaysDate}</HighlightText>{' '}
                หากพ้นระยะเวลาดังกล่าวแล้วยังไม่สามารถเข้าใช้งานคอร์สนี้ได้
                โปรดติดต่อ <EmailLink>babelcoder@gmail.com</EmailLink>
              </div>
              <CloseModal onClick={closeModal}>กลับสู่หน้าคอร์ส</CloseModal>
            </ModalWrapper>
          </Modal>
        )}
      </Wrapper>
    </NotRegisterOnlyGuard>
  );
};

export default Quotation;
